.fuel-modal {
  .ant-modal-header {
    background-color: #006cb5;

    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-modal-body {
    padding-top: 0px;
  }
}