$orange: #f85d49;
$orange-hover: #f85d49;
$primary: #006cb5;
$primary-hover: #ff715b;
$gray: #e8e8e8;
$blue: #4a90e2;
$color-primary: #c10000;
$color-gray: #999;
$color-red: #c10000;
$color-yallow:#ff9000;
$color-pink: #cb09d8;
$color-green: #36a200;
$color-green-hover: #46a200;
$color-blue: #038fde;
$color-white: #fff;

$color-todo: #038fde;
$color-doing: #00affe;
$color-completed: #46a200;
$orange-warning: #ff9000;

$item-hover: #ebffeb;